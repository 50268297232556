<template>
   <navi-bar>
     {{$t('front.common.board')}}
   </navi-bar>
  <section v-if="model" class="sub-box">
    <div class="msgreadwrap">
      <div class="msgreadhead">
        <div>
          <h2 class="msgreadname">
  <!--          <em class="waiticon">공지</em>-->
  <!--          <em class="noticeicon">안내</em>-->
            {{model.title}}
          </h2>
          <p class="msgreadtime">
            <span class="logo"><img src="@/assets/img/slogo.svg" />{{dateFormatForBoard(model.regDate)}} {{$t('front.board.look')}} {{thousand(model.viewCnt)}}</span>
            <!--span class="boardcomment" v-if="model.comment.length > 0">댓글<em class="rd">{{model.comment.length}}</em></span-->
          </p>
        </div>
        <div class="removedel">
          <a @click="onClickEdit()" v-if="model.regId == userData.memId">{{$t('front.board.re')}}</a>
          <a class="rd" @click="onClickRemove(model.boardIdx)" v-if="model.regId == userData.memId">{{$t('front.board.delete')}}</a>
        </div>
      </div>
<!--      <div class="addfile">-->
<!--        <a><img src="@/assets_mobile/img/icon_file.svg">casino.jpg</a>-->
<!--        <a><img src="@/assets_mobile/img/icon_file.svg">betting.jpg</a>-->
<!--      </div>-->
      <div class="msgreadconwrap">
        <div class="msgreadcon">
          {{model.content}}
        </div>
      </div>
    </div>
    <!--div class="btnwrap">
      <a class="selec" v-if="model.regId == userData.memId" @click="onClickEdit()">수정하기</a>
      <a class="all" v-if="model.regId == userData.memId" @click="onClickRemove(model.boardIdx)">삭제하기</a>
    </div-->
    <div class="boardcommentwrap mb100" v-if="model">
      <div class="commentinput">
        <h4 class="commenthead">{{$t('front.board.commentWrites')}}</h4>
        <div class="commenttxt">
          <textarea :placeholder="$t('front.board.commentPlaceholder')" v-model="replyContent"></textarea>
          <a @click="onSaveReply(model.boardIdx, replyContent)">{{$t('front.board.reg')}}</a>
        </div>
      </div>
      <span class="boardcomment" v-if="model.comment.length > 0">{{$t('front.board.comment')}} ({{model.comment.length}})</span>
      <template v-for="comment in model.comment" v-bind:key="comment.boardIdx">
        <div class="commentinput">
          <div class="commenttop">
            <h4 class="commenthead"><span :class="getMedalClassMobile(comment.memLevel)"></span>{{comment.memName}}</h4>
            <div class="commenttxt">
              <span>{{comment.content}}</span>
            </div>
            <span class="commenttime">{{dateFormatForBoard(comment.regDate)}}</span>
<!--            <div class="commentbtn">-->
<!--              <a>수정</a>-->
<!--              <a>삭제</a>-->
<!--            </div>-->
          </div>

          <!--div class="commentbottom">

            <template v-for="reComment in comment.comment" v-bind:key="reComment.boardIdx">
              <div class="commentre">
                <h4 class="commenthead"><span :class="getMedalClassMobile(reComment.memLevel)"></span>{{reComment.memName}}<span class="commenttime">{{dateFormatForBoard(reComment.regDate)}}</span></h4>
                <div class="commenttxt">
                  <input type="text" readonly="" :value="reComment.content">
                </div-->
<!--                <div class="commentbtn">-->
<!--                  <a>수정</a>-->
<!--                  <a>삭제</a>-->
<!--                </div>-->
              <!--/div>
            </template>
          </div-->

          <!--div class="commentbottom">
            <div class="commenttxt">
              <textarea placeholder="두개의 이벤트 모두 참여하였습니다." v-model="comment.replyContent"></textarea>
              <a @click="onSaveReply(comment.cmtIdx, comment.replyContent, 'C')">등록</a>
            </div>
          </div-->
        </div>
      </template>
    </div>
    <div class="btnwrap mb100">
      <a class="all" v-if="prevId" @click="onClickPrevNext('prev')">{{ $t('front.board.prev') }}</a>
      <a class="all" @click="onClickList()">{{$t('front.board.list')}}</a>
      <a class="all" v-if="nextId" @click="onClickPrevNext('next')">{{ $t('front.board.next') }}</a>
    </div>
  </section>
</template>

<script>
import NaviBar from '@/components/common/mobile/NaviBar'
import BoardRead from '@/views/member/board/read'

export default {
  name: 'board',
  components: {
    NaviBar
  },
  mixins: [BoardRead]
}
</script>

<style scoped src="@/styles_mobile/common.css"></style>
<style scoped src="@/styles_mobile/board.css"></style>
<style scoped src="@/styles_mobile/betlist.css"></style>
<style scoped src="@/styles_mobile/subcommon.css"></style>
